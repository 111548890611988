import React, {useEffect, useState} from "react";
import axios from "../axiosConfig";

import moment from "moment/moment";
import {getUser} from "../services/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {translateText} from "../services/translation";

const Transfer = () => {

    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [myBetsData, setMyBetsData] = useState([]);
    const [totalAmountFinal, setTotalAmountFinal] = useState(0);
    const user = getUser()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // const [searchQueryId, setSearchQueryId] = useState("");
    const searchQueryId = queryParams.get("id");
    const navigate = useNavigate()
    const fetchData = () => {
        let params = {
            fromDate: fromDate,
            toDate: toDate,
        };

        // Conditionally add the id if searchQueryId is not null
        if (searchQueryId) {
            params.id = searchQueryId;
        }
        axios
            .get("https://slot.probet.club/api/v2/userHistory/", {

                params: params,
            })
            .then((res) => {
                // setSearchQueryId(null);
                setMyBetsData(res.data);
                let totalAmount = 0.0
                for (let i = 0; i < res.data.transferHistory.length; i++) {
                    let x = res.data.transferHistory[i]
                    if (searchQueryId) {
                        if (x.username1 === searchQueryId){
                            totalAmount = totalAmount - parseFloat(x.amount)
                        } else {
                            totalAmount = totalAmount + parseFloat(x.amount)
                        }
                    }
                    else {
                        if (x.username1 === user.userId){
                            totalAmount = totalAmount - parseFloat(x.amount)
                        } else {
                            totalAmount = totalAmount + parseFloat(x.amount)
                        }
                    }

                }
                setTotalAmountFinal(totalAmount)

            })
            .catch((err) => {

            });
    };
    useEffect(() => {
        fetchData()
    }, []);

    const handleClick = () => {
        fetchData()
    }
    const minDate = "2024-11-01";
    return <div className="bg-[#474747] h-screen">
        <div className="flex items-center justify-between pt-4 px-4">
            <div className="text-[#ccc]" onClick={()=>{navigate('/')}}>{'<'} {translateText('Back')}</div>
            <h1 className="flex-1 text-center text-lg xl:text-xl text-[#ccc] font-bold">
                {translateText('Transfer History')}
            </h1>
            <div className="mr-16"></div> {/* Empty div to take up space on the right */}
        </div>
        <div className="flex items-center justify-center gap-4 p-4">
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fromDate}
                min={minDate}
                onChange={(e) => {
                    setFromDate(e.target.value);
                }}
            />
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={toDate}
                onChange={(e) => {
                    setToDate(e.target.value);
                }}
            />
            <button className="px-4 py-1 text-white bg-red-800 rounded" onClick={handleClick}>Go</button>



        </div>
        <div className="text-[#ccc]  flex items-center justify-center h-12 border-y border-[#888] py-1 my-2">
            <div className="h-full border-b-4 border-orange-400 flex justify-center items-center">{translateText('History')}</div>
        </div>
        <div className="h-full w-full ">
            <div className="w-full flex-flex-col divide-y divide-[#888] border-b border-[#888]">
                <div className="flex w-full bg-[#ccc] py-2 font-bold text-[#545454]">
                    <div className="w-[20%] pl-4">{translateText('Date')}</div>
                    <div className="w-[30%] pl-4">{translateText('From')}</div>
                    <div className="w-[30%] pl-4">{translateText('To_1')}</div>
                    <div className="w-[20%] pl-4">{translateText('Amount')}</div>
                </div>
                {
                    myBetsData && myBetsData.transferHistory && myBetsData.transferHistory.map((x) => {
                        return <div className="flex text-[#ccc] divide-x divide-[#888] py-1">
                            <div className="w-[20%] pl-4 text-[10px]">{x.transferDate}</div>
                            <div className="w-[30%] pl-4 text-[12px]">{x.username1}</div>
                            <div className="w-[30%] pl-4 text-[12px]">{x.username2}</div>
                            <div className={`w-[20%] pl-4  text-[12px] ${user.userId === x.username2 ? 'text-green-500' : 'text-red-500'}`}> {x.amount}</div>
                        </div>
                    })
                }
                <div className="flex w-full bg-[#ccc] py-2 font-bold text-[#545454]">
                    <div className="w-[20%] pl-4">Total</div>
                    <div className="w-[30%] pl-4"></div>
                    <div className="w-[30%] pl-4"></div>
                    <div className="w-[20%] pl-4">{totalAmountFinal}</div>
                </div>
            </div>

        </div>
    </div>
}

export default Transfer