import React, {useEffect, useState} from "react";
import axios from "../axiosConfig";
import {MdClose} from "react-icons/md";
import {IconContext} from "react-icons";
import moment from "moment/moment";
import {getUser} from "../services/auth";
import {useNavigate} from "react-router-dom";
import {translateText} from "../services/translation";


const UserItem = ({userData, length, key, isChild, fromDate, toDate, isSearch = false}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navigate = useNavigate();
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const diff = parseFloat(userData.betAmount.betAmount - userData.amountWon.amountWon - userData.commissionEarned.commissionEarned).toFixed(2)
    const y1 = diff > 0 ? 'text-green-500' : 'text-red-500'
    const y2 = length - 1 === key ? 'border-red-300' : ''
    return (userData.betAmount.betAmount !== 0 && (<div className={(isChild ? 'border-l border-red-300' : '')}>
        <div className='flex flex-col text-[10px]'>
            <div className={(isExpanded ? `flex bg-red-800` : 'flex justify-between')}>
                <div className="w-[14.28%] border border-[#888]">
                    <div className=" flex">
                        {!isSearch && userData.is_staff && userData.children.length !== 0 ? <button
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                            onClick={toggleExpansion}>{isExpanded ? '-' : '+'}</button> : <div className=''></div>}
                        <div
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userId}</div>
                    </div>
                </div>
                <div className="w-[14.28%] border  border-[#888]">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 justify-between flex-wrap"
                        onClick={() => {
                            navigate(`/bets?id=${userData.userId}&fromDate=${fromDate}&toDate=${toDate}`);
                        }}>
                        <div>{userData.betAmount.betAmount}</div>
                        <div
                            className="text-white bg-[#9FB7C0] rounded-full py-[0.5px] px-1 xl:px-2  ml-1 xl:ml-2">
                            {userData.betAmount.betAmountCount}
                        </div>
                    </div>
                </div>
                <div className="w-[14.28%] border border-[#888]">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 justify-between flex-wrap"
                        onClick={() => {
                            navigate(`/bets?id=${userData.userId}&status=WON&fromDate=${fromDate}&toDate=${toDate}`);
                        }}>
                        <div className="text-green-500">{userData.amountWon.amountWon}</div>
                        <div
                            className="text-white bg-[#9FB7C0] rounded-full py-[0.5px] px-1 xl:px-2 ml-1 xl:ml-2">
                            {userData.amountWon.amountWonCount}
                        </div>
                    </div>
                </div>

                <div className="w-[14.28%] border border-[#888]">
                    <div
                        className={`w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 ${y1}`}>{diff}</div>
                </div>

                <div className="w-[14.28%] border border-[#888]">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 justify-between flex-wrap"
                        onClick={() => {
                            navigate(`/bets?id=${userData.userId}&status=AWAITED&fromDate=${fromDate}&toDate=${toDate}`);
                        }}>
                        <div>{userData.active.active}</div>
                        <div
                            className="text-white bg-[#9FB7C0] rounded-full py-[0.5px] px-1 xl:px-2 ml-1 xl:ml-2">
                            {userData.active.activeCount}
                        </div>
                    </div>
                </div>

                <div className="w-[14.28%] border border-[#888]">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.commissionEarned.commissionEarned}</div>
                </div>
                <div className="w-[14.28%] border border-[#888]">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">0</div>
                </div>


            </div>

            {isExpanded && (<div className="flex flex-col">
                {userData.children.map((child, index) => (
                    <UserItem key={index} userData={child} length={userData.children.length}
                              isChild={true} fromDate={fromDate} toDate={toDate}/>))}
            </div>)}
        </div>
    </div>));
};

const UserItemCasino = ({userData, length, key, isChild, isSearch = false}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const y1 = userData.casino_amount.difference - userData.jackPotsWon.jackPotsWon > 0 ? 'text-green-500' : 'text-red-500'
    const y2 = length - 1 === key ? 'border-red-300' : ''
    return (userData.casino_amount.betAmount !== 0 && (<div className={(isChild ? 'border-l border-red-300' : '')}>
        <div className='flex flex-col text-[10px]'>
            <div className={(isExpanded ? `flex bg-red-800` : 'flex justify-between')}>
                <div className="w-1/5 border">
                    <div className=" flex">
                        {!isSearch && userData.is_staff && userData.children.length !== 0 ? <button
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                            onClick={toggleExpansion}>{isExpanded ? '-' : '+'}</button> : <div className=''></div>}
                        <div
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userId}</div>
                    </div>
                </div>
                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.casino_amount.betAmount}</div>
                </div>
                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.casino_amount.amountWon}</div>
                </div>

                <div className="w-1/5 border">
                    <div
                        className={`w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 ${y1}`}>{((userData.casino_amount.difference - userData.jackPotsWon.jackPotsWon).toFixed(2))}</div>
                </div>


                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.jackPotsWon.jackPotsWon}
                    </div>
                </div>


            </div>

            {isExpanded && (<div className="flex flex-col">
                {userData.children.map((child, index) => (
                    <UserItemCasino key={index} userData={child} length={userData.children.length}
                                    isChild={true}/>))}
            </div>)}
        </div>
    </div>));
};
const UserItemKeno = ({userData, length, key, isChild, isSearch = false}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const y1 = userData.keno_amount.difference > 0 ? 'text-green-500' : 'text-red-500'
    const y2 = length - 1 === key ? 'border-red-300' : ''
    return (userData.keno_amount.betAmount !== 0 && (<div className={(isChild ? 'border-l border-red-300' : '')}>
        <div className='flex flex-col text-[10px]'>
            <div className={(isExpanded ? `flex bg-red-800` : 'flex justify-between')}>
                <div className="w-1/5 border">
                    <div className=" flex">
                        {!isSearch && userData.is_staff && userData.children.length !== 0 ? <button
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4"
                            onClick={toggleExpansion}>{isExpanded ? '-' : '+'}</button> : <div className=''></div>}
                        <div
                            className="flex items-center justify-center px-2 overflow-hidden text-ellipsis py-2 xl:py-4">{userData.userId}</div>
                    </div>
                </div>
                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.keno_amount.betAmount}</div>
                </div>
                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">{userData.keno_amount.amountWon}</div>
                </div>

                <div className="w-1/5 border">
                    <div
                        className={`w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2 ${y1}`}>{userData.keno_amount.difference}</div>
                </div>


                <div className="w-1/5 border">
                    <div
                        className="w-full flex items-center px-2 overflow-hidden text-ellipsis py-1 xl:py-2">0
                    </div>
                </div>


            </div>

            {isExpanded && (<div className="flex flex-col">
                {userData.children.map((child, index) => (
                    <UserItemKeno key={index} userData={child} length={userData.children.length}
                                  isChild={true}/>))}
            </div>)}
        </div>
    </div>));
};

const Report = () => {
    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [userBets, setUserBets] = useState(null);
    const navigate = useNavigate()
    const fetchData = () => {
        let params = {
            fromDate: fromDate, toDate: toDate,
        };
        axios
            .get("https://api.prady.in/api/report", {

                params: params,
            })
            .then((res) => {
                // setSearchQueryId(null);
                setUserBets(res.data);

            })
            .catch((err) => {

            });
    };
    useEffect(() => {
        fetchData()
    }, []);

    const handleClick = () => {
        fetchData()
    }
    // Set the minimum date to June 30, 2024
    const minDate = "2024-11-01";

    return <div className="bg-[#474747] h-screen ">
        <div className="flex items-center justify-between pt-4 px-4">
            <div className="text-[#ccc]" onClick={() => {
                navigate('/')
            }}>{'<'} {translateText('Back')}
            </div>
            <h1 className="flex-1 text-center text-lg xl:text-xl text-[#ccc] font-bold">
                Report
            </h1>
            <div className="mr-16"></div>
            {/* Empty div to take up space on the right */}
        </div>
        <div className="flex items-center justify-center gap-4 p-4">
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fromDate}
                min={minDate}
                onChange={(e) => {
                    setFromDate(e.target.value);
                }}
            />
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={toDate}
                onChange={(e) => {
                    setToDate(e.target.value);
                }}
            />
            <button className="px-4 py-1 text-white bg-red-800 rounded" onClick={handleClick}>Go</button>


        </div>
        {/*<div className="relative flex  w-full items-center bg-[#474747] px-2 py-2 justify-between">*/}
        {/*    <input*/}
        {/*        type="text"*/}
        {/*        className="bg-[#282621] text-[#ccc] rounded pl-4 pr-10 py-2 h-8"*/}
        {/*        placeholder="Search User"*/}
        {/*        onChange={handleChange}*/}
        {/*        value={searchText}*/}

        {/*    />*/}
        {/*    <div onClick={() => handleChangeEmpty()}>*/}
        {/*        <IconContext.Provider value={{color: "gray", size: "2em", className: "ml-8"}}>*/}
        {/*            <MdClose/>*/}
        {/*        </IconContext.Provider>*/}
        {/*    </div>*/}

        {/*</div>*/}
        {userBets && <>

            <div className="text-[#ccc]  flex items-center justify-center h-12 border-y border-[#888] py-1 my-2">
                <div className="h-full border-b-4 border-orange-400 flex justify-center items-center">{translateText('Sports')}</div>
            </div>

            <div className=" w-full   overflow-x-auto">
                <div className="w-[550px] bg-[#ccc] flex-flex-col divide-y divide-[#888] border-b border-[#888]">
                    <div className="flex  py-2 font-bold text-[#545454] text-[12px]">
                        <div className="w-[14.28%] pl-4">{translateText('User')}</div>
                        <div className="w-[14.28%] pl-4">{translateText('Stake')}</div>
                        <div className="w-[14.28%] pl-4">{translateText('Win_1')}</div>
                        <div className="w-[14.28%] pl-4">{translateText('Diff')}</div>
                        <div className="w-[14.28%] pl-4">{translateText('Active')}</div>
                        <div className="w-[14.28%] pl-4">%</div>
                        <div className="w-[14.28%] pl-4">{translateText('Jackpots')}</div>
                    </div>
                    <div className="bg-[#474747] text-[#ccc]">
                        {userBets.length !== 0 && userBets.children.map((user, index) => (
                            <UserItem key={index} userData={user} isChild={false} toDate={fromDate} fromDate={toDate}/>))}
                    </div>
                    <div className="flex  py-2 font-bold text-[#545454] text-[10px]">
                        <div className="w-[14.28%] pl-4">Total</div>
                        <div className="w-[14.28%] pl-4">{userBets.betAmount.betAmount}</div>
                        <div className="w-[14.28%] pl-4">{userBets.amountWon.amountWon}</div>
                        <div className="w-[14.28%] pl-4">
                            {parseFloat(userBets.betAmount.betAmount - userBets.amountWon.amountWon - userBets.commissionEarned.commissionEarned).toFixed(2)}
                        </div>
                        <div className="w-[14.28%] pl-4">{userBets.active.active}</div>
                        <div className="w-[14.28%] pl-4">{userBets.commissionEarned.commissionEarned}</div>
                        <div className="w-[14.28%] pl-4">0</div>
                    </div>
                </div>

            </div>
            <div className="text-[#ccc]  flex items-center justify-center h-12 border-y border-[#888] py-1 my-2">
                <div className="h-full border-b-4 border-orange-400 flex justify-center items-center ">{translateText('Casino')}</div>
            </div>
            <div className=" w-full   overflow-x-auto">
                <div className="w-[550px] bg-[#ccc] flex-flex-col divide-y divide-[#888] border-b border-[#888]">
                    <div className="flex  py-2 font-bold text-[#545454] text-[12px]">
                        <div className="w-1/5 pl-4">User</div>
                        <div className="w-1/5 pl-4">{translateText('Stake')}</div>
                        <div className="w-1/5 pl-4">{translateText('Win_1')}</div>
                        <div className="w-1/5 pl-4">{translateText('Diff')}</div>
                        <div className="w-1/5 pl-4">{translateText('Jackpot')}</div>

                    </div>
                    <div className="bg-[#474747] text-[#ccc]">
                        {userBets.length !== 0 && userBets.children.map((user, index) => (
                            <UserItemCasino key={index} userData={user} isChild={false}/>
                        ))}
                    </div>
                    <div className="flex  py-2 font-bold text-[#545454] text-[10px]">
                        <div className="w-1/5 pl-4">Total</div>
                        <div className="w-1/5 pl-4">{userBets.casino_amount.betAmount}</div>
                        <div className="w-1/5 pl-4">{userBets.casino_amount.amountWon}</div>
                        <div className="w-1/5 pl-4">{(userBets.casino_amount.difference - userBets.jackPotsWon.jackPotsWon).toFixed(2)}</div>
                        <div className="w-1/5 pl-4">{userBets.jackPotsWon.jackPotsWon}</div>

                    </div>
                </div>

            </div>
            <div className="text-[#ccc]  flex items-center justify-center h-12 border-y border-[#888] py-1 my-2">
                <div className="h-full border-b-4 border-orange-400 flex justify-center items-center">{translateText('Virtual')}</div>
            </div>
            <div className=" w-full   overflow-x-auto">
                <div className="w-[550px] bg-[#ccc] flex-flex-col divide-y divide-[#888] border-b border-[#888]">
                    <div className="flex  py-2 font-bold text-[#545454] text-[12px]">
                        <div className="w-1/5 pl-4">User</div>
                        <div className="w-1/5 pl-4">{translateText('Stake')}</div>
                        <div className="w-1/5 pl-4">{translateText('Win_1')}</div>
                        <div className="w-1/5 pl-4">{translateText('Diff')}</div>
                        <div className="w-1/5 pl-4">{translateText('Jackpot')}</div>

                    </div>
                    <div className="bg-[#474747] text-[#ccc]">
                        {userBets.length !== 0 && userBets.children.map((user, index) => (
                            <UserItemKeno key={index} userData={user} isChild={false}/>
                        ))}
                    </div>
                    <div className="flex  py-2 font-bold text-[#545454] text-[10px]">
                        <div className="w-1/5 pl-4">Total</div>
                        <div className="w-1/5 pl-4">{userBets.keno_amount.betAmount}</div>
                        <div className="w-1/5 pl-4">{userBets.keno_amount.amountWon}</div>
                        <div className="w-1/5 pl-4">{userBets.keno_amount.difference}</div>
                        <div className="w-1/5 pl-4">0</div>

                    </div>
                </div>

            </div>

        </>}

        {!userBets && <div className="flex items-center justify-center h-1/2 w-full">Loading</div>}

    </div>
}

export default Report