import React, {useEffect, useState} from "react";
import axios from "../axiosConfig";

import moment from "moment/moment";
import {getUser} from "../services/auth";
import {useLocation, useNavigate} from "react-router-dom";
import {translateText} from "../services/translation";
import MyBetSingleSlip from "../components/MyBetsPage/MyBetSingleSlip";


const MyBets = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search);
    const searchQueryId = queryParams.get("id");
    const status = queryParams.get("status");
    const queryTo = queryParams.get("toDate");
    const queryFrom = queryParams.get("fromDate");
    const [fromDate, setFromDate] = useState(queryTo ? queryTo : moment().format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(queryFrom? queryFrom: moment().format("YYYY-MM-DD"));
    const user = getUser()
    const minDate = "2024-11-01";
    const [myBetsData, setMyBetsData] = useState([]);
    const fetchData = () => {
        axios
            .get("https://slot.probet.club/data/makeBet", {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                },
                params: {
                    fromDate: fromDate,
                    toDate: toDate,
                    searchQueryId: searchQueryId,
                    filterState: status

                },
            })
            .then((res) => {
                setMyBetsData(res.data.betHistory);
                console.log(res.data)
            })
            .catch((err) => {

            });
    };

    useEffect(() => {
        fetchData()
    }, []);
    const handleClick = () => {
        fetchData()
    }
    let dateSlip = ''

    const dateSet = (el2) => {
        dateSlip = el2.betDate.split(' ')[0]
    }
    return <div className="bg-[#474747]">
        <div className="flex items-center justify-between pt-4 px-4">
            <div className="text-[#ccc]" onClick={()=>{navigate('/')}}>{'<'} {translateText('Back')}</div>
            <h1 className="flex-1 text-center text-lg xl:text-xl text-[#ccc] font-bold">
                {translateText('Bet History')}
            </h1>
            <div className="mr-16"></div> {/* Empty div to take up space on the right */}
        </div>
        <div className="flex items-center justify-center gap-4 p-4">
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={fromDate}
                min={minDate}
                onChange={(e) => {
                    setFromDate(e.target.value);
                }}
            />
            <input
                type="date"
                className="w-36 px-2 py-1 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={toDate}
                onChange={(e) => {
                    setToDate(e.target.value);
                }}
            />
            <button className="px-4 py-1 text-white bg-red-800 rounded" onClick={handleClick}>Go</button>



        </div>
        <div className="h-full w-full ">
            <div className="w-full">
                {

                    myBetsData && myBetsData.length!== 0 && myBetsData.map((el2, ind2) => {


                        return (
                            <>
                                {el2.betDate.split(' ')[0] !== dateSlip ?
                                    <div className="flex bg-custom-bg-back text-[#ccc] py-2 my-0.5 px-2 justify-between text-base ">
                                        {dateSet(el2)}
                                        { el2.betDate.split(' ')[0]}
                                    </div>
                                    : <></>}

                                <MyBetSingleSlip
                                    el2={el2}
                                    // handleCancelBet={handleCancelBet}


                                />
                            </>

                        );
                    })}
            </div>
            <div className="my-10">
                <div className="py-10"></div>
            </div>
        </div>

    </div>
}

export default MyBets